<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      fill-rule="evenodd"
      d="M8.438 0H6.562v9.412h1.875V0zm3.374 2.259L10.5 3.576C12 4.612 13.125 6.4 13.125 8.471c0 3.105-2.531 5.647-5.625 5.647S1.875 11.576 1.875 8.47a5.58 5.58 0 0 1 2.719-4.8L3.187 2.259C1.313 3.67 0 5.929 0 8.47 0 12.61 3.375 16 7.5 16S15 12.612 15 8.47c0-2.54-1.313-4.8-3.188-6.211z"
  /></g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
